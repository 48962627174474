<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo main__logo__title">
        <img src="../../assets/images/logo/logo.svg" alt="" />
        <h2 class="brand-text ml-1 title">
          Enterprise<br />
          <span class="title_go">GoDigital</span>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            Please Verify Account with the OTP code sent? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            A code has been sent to your email address. Please enter the code below to
            verify your account.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label="Enter OTP" label-for="otp">
                <validation-provider #default="{ errors }" name="OTP" :rules="otpRules">
                  <b-form-input
                    v-model="otp"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter 6 digit OTP"
                    maxlength="6"
                  />
                  <small class="text-danger">{{ getErrorMessage(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block :disabled="loading">
                <b-spinner v-if="loading" small></b-spinner>
                {{ loading ? "Verifying..." : "Verify OTP" }}
              </b-button>

              <p v-if="countdown > 0" class="text-center my-2">
                Resend OTP in {{ countdown }} seconds
              </p>
              <p
                v-else
                class="text-start my-2 font-bold"
                @click="resendOTP"
                style="cursor: pointer"
              >
                Resend OTP
              </p>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2" @click="logout">
            <b-link> <feather-icon icon="ChevronLeftIcon" /> Back to login </b-link>
          </p>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import api from "@/api/api-v1-config";
import { initialAbility } from "@/libs/acl/config";
export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BSpinner,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      required,
      email,
      otp: "",
      otpRules: "required|length:6",
      loading: false,
      countdown: 30,
      timer: null,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    displayCountdown() {
      return this.countdown > 0
        ? `Resend OTP in ${this.countdown} seconds`
        : "Resend OTP";
    },
  },
  methods: {
    getErrorMessage(error) {
      if (error === "identity.invalid otp") {
        return "The OTP code you provided is incorrect, please provide the correct code";
      }
      return error; // return the original error message for other errors
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    clearCache() {
      caches.keys().then(function (keys) {
        keys.forEach(function (key) {
          caches.delete(key);
        });
      });
    },

    async resendOTP() {
      try {
        const email = JSON.parse(localStorage.getItem("userData")).email;
        const response = await api.post(
          `/tokens/resendotp?email=${encodeURIComponent(email)}`
        );

        this.$swal({
          title: "OTP Send!",
          text: response.data.message,
          icon: "success",
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        });
      } catch (error) {
        this.$swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.countdown = 30;
      this.startCountdown();
    },
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.clear();
      this.clearCache();

      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    async validationForm() {
      if (this.otp.length !== 6) {
        this.$refs.simpleRules.setErrors({
          OTP: ["The OTP field must be exactly 6 characters"],
        });
        return;
      }

      const email = JSON.parse(localStorage.getItem("userData")).email;
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.loading = true;
      try {
        const response = await api.get(
          `/tokens/2fatoken?email=${encodeURIComponent(email)}&token=${this.otp}`
        );
        this.loading = false;
        if (response.data.success) {
          this.$router.push({ name: "dashboard-godigital" });
          this.$router.then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Welcome ${userData.fullName || userData.username}`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `You have successfully logged in. Now you can start to explore!`,
              },
            });
          });
        } else {
          this.$refs.simpleRules.setErrors({
            OTP: [response.data.message || "Verification failed. Try again."],
          });
        }
      } catch (error) {
        this.loading = false;
        if (error.response && error.response.data && error.response.data.message) {
          this.$refs.simpleRules.setErrors({
            OTP: [error.response.data.message],
          });
        } else {
          this.$refs.simpleRules.setErrors({
            OTP: ["An error occurred. Please try again."],
          });
        }
      }
    },
  },
  created() {
    this.startCountdown();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.main__logo__title .title {
  color: #4065ab;
  font-weight: 700;
}

.main__logo__title .title_go {
  color: #b0cc48;
  font-weight: 700;
}

.main__logo__title img {
  width: 60px;
  height: auto;
}

.otp-input {
  width: 30px;
  margin-right: 5px;
  text-align: center;
}
</style>
